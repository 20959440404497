import React, { useEffect, useState } from 'react';
import { registerCustomer } from '../services/krti.service';
import { useFormik } from "formik";
import * as Yup from "yup";
import Leftbackarrow from "../assets/images/leftbackarrow.svg";

const DialCodes = [
    {
        name: "India",
        dial_code: "+91",
        code: "IN",
        active_flag: "Y",
    },
    {
        name: "Saudi Arabia",
        dial_code: "+966",
        code: "SA",
        active_flag: "Y",
    },
    {
        name: "Kuwait",
        dial_code: "+965",
        code: "KW",
        active_flag: "Y",
    },
    {
        name: "United Arab Emirates",
        dial_code: "+971",
        code: "AE",
        active_flag: "Y",
    },
    {
        name: "Croatia",
        dial_code: "+385",
        code: "HR",
        active_flag: "Y",
    },
]

const LinkAccount = ({ phone, setPhone, handleNextStep, setWidgetCompleted, widgetCompleted, payload }) => {
    const [error, setError] = useState('');

    useEffect(() => {
        setPhone(payload?.mobile);
    }, []);

    const register = async (dial_code,phone) => {
        try {
            const req = { mobile: dial_code + phone, dial_code: dial_code };
            const res = await registerCustomer(req);
            if (res?.status === 200 && res?.data?.statusCode === 200) {
                setError('')
                sessionStorage.setItem('K-user', JSON.stringify(req));
                handleNextStep();
            } else {
                setError(res?.data?.message || res?.message)
                // toast.error(res?.message || res?.data?.message);
            }
        } catch (error) {
            if (error?.message) {
                const msg = error?.message?.split(":")?.[1]?.trim();
                console.error(msg);
                setError(error?.message)
            }
            console.log(error.message);
        }
    };



    const { handleBlur, handleChange, handleSubmit, setFieldValue, values, errors, touched, resetForm, setErrors } = useFormik({
        initialValues: {
            phoneNumber: phone,
            phoneCode: ('+' + payload?.mobile_code) || ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            phoneNumber: Yup.string()
                .required("Phone number is required")
                .matches(/^[a-zA-Z0-9\-().\s]{8,12}$/, "Invalid phone number"),
            phoneCode: Yup.string()
                .required("phonecode is required"),
        }),
        onSubmit: async ({ phoneCode, phoneNumber }, { resetForm }) => {
            try {
                let cleanedPhoneCode = phoneCode.replace("+", "");
                setPhone(phoneNumber);
                sessionStorage.setItem('phoneCode', cleanedPhoneCode);
                sessionStorage.setItem('phoneNumber', phoneNumber);
                console.log('cleanedPhoneCode::: ', cleanedPhoneCode);
                console.log('phoneNumber::: ', phoneNumber);
                register(cleanedPhoneCode,phoneNumber)
            } catch (error) {
                if (error?.message) {
                    const msg = error?.message?.split(":")?.[1]?.trim();
                    console.error(msg)
                }
                console.log(error.message);
            }
        },
    });

    return (
        <>
            <div className='loginScreenWrapper'>

                <div className='mb_34'>
                    <button type='button' onClick={() => setWidgetCompleted(!widgetCompleted)} className='blackStallionCircle backCirclebtn'>
                        <img className='ml_min2' src={Leftbackarrow} alt='Leftbackarrow' />
                    </button>
                </div>

                <div>
                    {/* <img src={logo} alt="Logo" className='pbottom20' /> <br />
                    <img src={paymentbanner} alt="Banner" /> */}
                    <h2 className='fontSize40 blackStallionText fontWeight400 mb_40' style={{lineHeight:'50px'}}>Log in to <br />
                        Krti wallet.</h2>
                    <form onSubmit={handleSubmit}>

                        <label className='fontSize16 fontWeight400 blackStallionText mb_12 '>Phone Number</label>
                        {/* <div>
                                 Here goes your select component 
                            </div> */}
                        {/* <input className='form-control'
                            type="text"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        /> */}
                        <div className='displayFlex mb_16 alignItem_center gap12'>
                            {/* <div>
                                <select
                                    className="whiteSelectInput"
                                    name="phoneCode"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phoneCode}
                                >
                                    <option value="">Select Code </option>
                                    {DialCodes?.map((code, i) => {
                                        return (
                                            code.active_flag === "Y" && (
                                                <option key={i} value={code.dial_code}>
                                                    {`${code.dial_code} (${code.code})`}
                                                </option>
                                            )
                                        );
                                    })}
                                </select>
                                {errors.phoneCode && touched.phoneCode ? (
                                    <p className="error">
                                        {errors.phoneCode}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div> */}
                            <div className='flexOne'>
                                <input
                                    className="whiteInput"
                                    type="text"
                                    placeholder="Enter phone number"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    readOnly={true}
                                />
                                {errors.phoneNumber && touched.phoneNumber ? (
                                    <p className="error">
                                        {errors.phoneNumber}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div>
                            {error && <p className=' textAlignCenter errorMsg'>{error}</p>}
                        </div>


                        <p className='fontSize16 fontWeight500 blackStallionText mb_70 textAlignLeft'>if you don't have a krti wallet, it will be created.</p>
                        <div>
                            <button type='submit' className='loginbtn height50px'>Log in</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default LinkAccount;



